.progress-container {
  width: 60%;
  height: 20px;
  align-items: center;
  align-content: center;
  background-color: lightgray;
}

.bar {
  height: 100%;
  background-color: green;
  width: var(--progress, 0%);
  transition: width 0.5s ease;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line{
  padding: auto;
  margin: 1em;
}