.Dashboard{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.stats{
    max-width: 100%;   
    padding: 1em;    
}

p {
    display: flex;
    justify-content: center;
    color: linen;    
}
a {
    color: #0060B6;
    text-decoration: none;
    color: linen;   
}
.statsHeader{    
    margin: 1vh auto 0;
    font: 2em sans-serif;
    justify-content: center;
    font-weight: bolder;
}

.statsBodyMOVR{
    margin: 1vh auto 0;
    width: 90%;
    min-height: fit-content;
    background: rgb(244, 247, 243);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;    
    background-image: url(../src/images/moonriver.png)
}

.totalSupply .inflation .collatorCount .totalStaked .apprxAPY .minBond .roundLength .blockTime .unBondDuration .rewardsDistrubution{
    padding: 1em;
    display: flex;
    width: 15%
}

.content{
    background: rgb(255, 255, 255);
    display: flex;
    margin: 2em;    
    flex-direction: column;  
    flex: 1 1 100px      
}
.labelMOVR{
    padding: 0.5em;
    justify-content: center;
    background: #FFBF00;
    font-weight: bold;
    color: rgb(80, 78, 77);
}
.value{
    padding: 0.5em;
    text-align: center;    
}

.As-of{
    text-align: left;
}