.charts{  
  width: 95%;
  height: 420px;
  margin: 1vh auto 0;
  padding: 2em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display:flexbox;
}
.chart-1 {
  width: 45%;
  float: left;
  background-color: rgb(236, 244, 247);
  border: thin solid darkgray;
}
.chart-2{
  float: right;
  width: 45%;
  background-color: rgb(236, 244, 247);
  border: thin solid darkgray;
}
.chart-Header{
  text-align: center;
  
}