  .container > form {
    width: 90%;
    border: 1px solid #dfdfdf;    
    border-radius: 5px;
    background: #fff;
    margin: 1vh auto 0;
    padding: 1em;
    font: 1em sans-serif;    
    text-align: center;
    font-weight: bolder;
  }
  pre {
    width: 70%;
    color: #fff;
    font-size: larger;
  }
  

  .button {
    color: rgb(106, 110, 114);
    width: 10%;
    margin: 1vh auto 0;
    transition-duration: 0.4s;
    font-size: larger;
  }
  
  .button:hover {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  .textbox{
      width: 90%;
      height: 90%;
      text-align: center;
      font-size: larger;
  }
  .field{
    width: 40%;
    border: 1px solid #dfdfdf;    
    border-radius: 5px;
    background: #fff;
    margin: 1vh auto 0;
    padding: 1em;
    font: 1em sans-serif;    
    text-align: center;
    font-weight: bolder;
  }

.results{
    display: flex;
    gap: 1em;
    justify-items: center;
    align-items: center;
    font-weight: bold;
}

.moonbeam .moonriver{
    display: flex;    
}
.InActive{
    color: crimson;
  }
  .InEligible{
    color: crimson;
  }
  .Eligible{
    color: rgb(54, 214, 22);
  }
h2{
    color: aliceblue;
    align-items: center;
    text-align: center;
}
h4{
    color: aliceblue;
    align-items: center;
    text-align: center;
}
.table-responsive{
    text-align: center;

}