.statsTable {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.table td,
.table th {
  padding: 12px 15px;
  border: 1px solid rgb(105, 105, 104);
  text-align: center;
}

.table-responsive {
  justify-content: center;
  text-align: center;
  width: 90%;
  padding: 1em;
}
.table {
  align-items: center;
  table-layout: fixed;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: auto;
}
.column-header {
  color: rgb(216, 198, 36);
  text-align: center;
  justify-content: center;
  padding: 2em;
  font-weight: bolder;
  font-size: large;
  overflow: hidden;
}

.row {
  color: rgb(243, 237, 237);
  margin: 1em;
  display: table-row;
}
.InActive {
  color: crimson;
}
.data-labels {
  color: rgb(203, 231, 75);
  text-align: center;
  padding: 1em;
}
.table-title {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
}
